.container {
  height: calc(100vh - 40px);
  width: 100%;
  border-top: 1px solid #dee2e6;
}

.container > *:first-child {
  width: 60%;
  border-right: 1px solid #dee2e6;
}
.container > *:last-child {
  width: 40%;
}

.contentContainer {
  padding: 24px;
  height: calc(100% - 55px);
  overflow: auto;
}

.buttonContainer {
  height: 55px;
  padding: 12px 24px;
  background-color: #f8f9fa;
}
.buttonContainer button {
  margin-right: 16px;
}
.buttonContainer:last-child {
  background-color: transparent;
  width: auto;
}

.dropdownContainer {
  margin: 2px 8px 0 18px;
}

.dropdownContainer button {
  min-width: 200px;
}

.editorContainer {
  width: 100%;
  height: 100vh;
}

.editorContainer .editorContent {
  height: calc(100% - 55px);
  width: 100%;
}

.formContainer,
.fileContainer {
  width: 50%;
  overflow: auto;
  padding: 24px;
}

.formContainer {
  min-width: 400px;
  border-right: 1px solid #dee2e6;
}

.adminEditorContainer {
  padding: 24px;
  width: 100%;
  overflow: auto;
}

.dropdownGeoContainer {
  margin: 0 16px 4px 0;
}

.dropdownGeoContainer button {
  min-width: 200px;
}

.showOnParentHover {
  opacity: 0;
}

.modelContainer:hover .showOnParentHover {
  opacity: 1;
}

.modelViewerOuterBorder {
  border: 1px solid #ff286d;
  position: relative;
}

.modelViewerInnerBorder {
  width: calc(100% - 26px);
  height: calc(100% - 26px);
  margin: calc(13px - 100%) 13px 13px;
  border: 1px solid black;
  position: absolute;
}

.collectionsManagerContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: white;
  z-index: 1040;
}
