.addImage {
    background-color: #f2f2f7;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .addImage:hover {
    background-color: #e2e2ea;
  }
  
  .addImage svg {
    color: #859eb9;
    width: 24px;
    height: 24px;
  }
  
  .addImage i {
    color: #6c757d;
    font-size: 12px;
  }