.dropdownTags > button,
.dropdownTags > div {
  min-width: 460px;
}

.dropdownTags > div {
  height: 300px;
  padding-bottom: 0;
}

.blockItem {
  display: inline-block;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin: 0 6px 6px 0;
  padding-left: 6px;
  font-size: 14px;
}

.blockItem:hover {
  background-color: #e7e8e9;
}

.blockItem svg {
  width: 32px;
  height: 32px;
  padding: 6px;
  opacity: 0.8;
}

.blockItem svg:hover {
  opacity: 1;
}
