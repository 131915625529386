.editorContainer {
  width: 100%;
  height: 100vh;
}

.w100EditorContainer {
  padding: 24px;
  width: 100%;
  overflow: auto;
}

.editorContainer .editorContent {
  height: calc(100% - 55px);
  width: 100%;
  
  overflow: auto;
}

.buttonContainer {
  height: 55px;
  padding: 12px 24px;
  background-color: #f8f9fa;
}
.buttonContainer button:not(:last-child) {
  margin-right: 16px;
}

.buttonContainer.edit-button {
  background-color: transparent;
  width: auto;
}

.w50EditorContainer {
  width: 50%;
  overflow: auto;
  padding: 24px;
}

.w50EditorContainer:nth-of-type(1) {
  border-right: 1px solid #dee2e6;
}
