.tag {
  display: inline-block;
  margin: 0 3px 3px 0;
  padding: 3px 5px;
  border-radius: 4px;
  line-height: 1em;
}

.pointer {
  cursor: pointer;
}

.noUnderline {
  text-decoration: none !important;
}

.marker {
  width: 8px;
  height: 8px;
  background-color: black;
  border: 1px solid white;
  border-radius: 4px;
}

.whiteSpacePreLine {
  white-space: pre-line;
}

.errorBorderDropDown button {
  border-color: #dc3545;
}

.errorBorderDropDown button:hover {
  border-color: #dc3545;
}

.errorBorder {
  outline: 1px solid #dc3545;
  outline-offset: -2px;
}

.required {
  color: #dc3545;
}
