.dropdownGeoContainer button {
  min-width: 300px;
}

.navbarContainer {
  z-index: 1030;
}

.navbarMain {
  margin: 0 24px 0 16px;
  width: 100%;
}

.adInfoContainer {
  height: 64px;
}

.imageButton {
  cursor: pointer;
  margin-right: 22px;
}

.imageButton:hover {
  opacity: 0.9;
}

.addImage {
  width: 280px;
  height: 116px;
  background-color: #f2f2f7;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addImage:hover {
  background-color: #e2e2ea;
}

.addImage svg {
  color: #859eb9;
  width: 24px;
  height: 24px;
}

.addImage i {
  color: #6c757d;
  font-size: 12px;
}

.checkboxList {
  height: calc(100% - 68px);
  overflow: auto;
  margin: 0 -24px -24px 0;
}

.lightText {
  color: #6c757d;
}
