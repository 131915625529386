.publicTopContainer {
  margin: 32px 0;
}

.mapContainer {
  width: 160px;
  height: 160px;
}

.textBlock {
  margin: 16px 0;
  color: #343a40;
}

.textBlock h6,
.textBlock h4 {
  margin-bottom: 4px;
  font-weight: 600;
  color: black;
}

.tagsBlock {
  margin: 16px 0 32px;
  color: #343a40;
}

.imagesContainer {
  margin-top: 24px;
  display: inline-flex;
  flex-wrap: wrap;
}

.imagesContainer > * {
  margin: 4px 4px 0 0;
}

.addImage {
  width: 64px;
  height: 64px;
  background-color: #f2f2f7;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addImage:hover {
  background-color: #e2e2ea;
}

.addImage svg {
  color: #859eb9;
  width: 24px;
  height: 24px;
}
