.tbodyWithCenteredTd td {
  vertical-align: middle;
}

.trHead {
  background-color: white;
  border-top-width: 0 !important;
}

.tdContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.showOnRowHover {
  opacity: 0;
}

.tbodyWithCenteredTd tr:hover .showOnRowHover {
  opacity: 0.8;
}

.tbodyWithCenteredTd tr:hover .showOnRowHover:hover {
  opacity: 1;
}

.removedRow {
  opacity: 0.4;
  pointer-events: none;
}
