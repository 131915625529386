.imageButton {
  cursor: pointer;
  margin: 0 12px;
}

.imageButton:hover {
  opacity: 0.8;
}

.imageButtonDisabled {
  margin: 0 12px;
  opacity: 0.3;
}
