.formElementContainer {
  margin-bottom: 32px;
}

.formElementContainer:last-child {
  margin-bottom: 0;
}

.title {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
}

.radiobuttonContainer {
  display: flex;
  gap: 6px;
}

.radiobuttons {
  display: flex;
  gap: 30px;
}

.visibilityScheduleElements {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
}

.visibilityScheduleElement {
  display: flex;
  align-items: center;
  gap: 20px;
}

.visibilityScheduleDatePicker {
  width: 100%;
}

.visibilityScheduleTimePicker {
  width: 100%;
}
