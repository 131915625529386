.searchContainer {
  width: calc(100% - 680px);
  min-width: 150px;
  margin-top: 3.5px;
}

.searchInputGroup {
  max-width: 700px;
  position: relative;
  width: 100%;
}

.searchClear {
  height: 29px;
  right: 1px;
  top: 1px;
  opacity: 0;
  position: absolute;
  z-index: 10;
  background-color: white;
  padding: 1px 6px 0;
  cursor: pointer;
  border-radius: 4px;
}

.searchInputGroup:hover .searchClear {
  opacity: 1;
}

.searchInputGroup:hover .searchClear svg {
  opacity: 0.5;
}

.searchContainer:hover .searchClear:hover svg {
  opacity: 0.8;
}

.questionIcon {
  background-color: #bfbfc0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: white;
  padding: 0 6px;
  margin-left: 8px;
  opacity: 0.8;
  cursor: pointer;
  line-height: 20px;
}

.questionIcon:hover {
  opacity: 1;
}
