.closeButtonContainer {
  background-color: white;
  margin: 16px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: 0;
}

.closeButton {
  padding: 14px !important;
}
