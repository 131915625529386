.imgContainer {
  position: relative;
}

.imgContainer:hover .darkContainer {
  opacity: 1;
}

.darkContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  opacity: 0;
}

.delContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  cursor: pointer;
}

.delContainer svg {
  color: white;
  opacity: 0.7;
}
.delContainer:hover svg {
  opacity: 1;
}

.fsContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  padding: 16px;
}
.fsContainer img {
  max-width: 100%;
  max-height: 100%;
}
